import Layout from '@/layout'

const gameManage = {
  path: '/game-manage',
  component: Layout,
  redirect: '/game-manage/list',
  name: 'gameManage',
  meta: {
    title: 'gameManage',
    icon: 'people'
  },
  children: [
    {
      path: 'list',
      component: () => import('@/views/game-manage/list/index.vue'),
      name: 'gameList',
      meta: { title: 'gameList', noCache: false }
    },
    {
      path: 'game-home-setting',
      component: () => import('@/views/game-manage/home/setting.vue'),
      name: 'GameHomeSetting',
      meta: {
        title: 'gameHomeSetting',
        noCache: false
      }
    },
    {
      path: 'game-home-page-list/:navigationId',
      component: () => import('@/views/game-manage/home/list.vue'),
      name: 'GameHomePageList',
      props: true,
      meta: {
        title: 'gameHomePageList',
        noCache: false,
        custom: true
      },
      hidden: true
    },

    {
      path: 'game-home-components',
      component: () => import('@/views/game-manage/home-components/list.vue'),
      name: 'GameHomeComponents',
      meta: {
        title: 'gameHomeComponents',
        noCache: false
      }
    },
    {
      path: 'game-plate-setting',
      component: () => import('@/views/game-manage/home-components/plate'),
      name: 'GamePlateSetting',
      meta: {
        title: 'gamePlateSetting',
        noCache: true
      },
      hidden: true
    },
    {
      path: 'game-rank',
      component: () => import('@/views/game-manage/rank/index.vue'),
      name: 'GameRank',
      meta: {
        title: 'gameRank',
        noCache: true
      },
      hidden: true
    },
  ]
}

export default gameManage
